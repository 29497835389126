import './style.css';
import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Tone from 'tone';
import { default as first } from './first.js';
import { default as sevens } from './sevens.js';

const PlayButton = (props) => {
    const {stop,
        playing, setPlaying,
        end, setEnd,
        currentTrack, setCurrentTrack,
        clean, setClean,
        setSetMod,
        tracks} = props.state;
    const p = props.state;
    const [thisPlaying, setThisPlaying] = useState(false);

    const startTrack = (track) => {
        tracks[track].activate().then(([play, clean, setMod]) => {
            Tone.Transport.start();
            setSetMod(() => setMod);
            const f = play();
            setEnd(() => f)
            setClean(() => clean);
        });
        setPlaying(true)
    }

    const stopTrack = () => {
        stop();
        clean();
        setPlaying(false)
    }
    
    const handleClick = () => {
        if (playing && (currentTrack == props.track || !props.track)) {
            stopTrack();
        } else if (playing && currentTrack != props.track) {
            stopTrack();
            setCurrentTrack(props.track);
            startTrack(props.track);
        } else if (!playing && !props.track) {
            startTrack(currentTrack);
        } else if (!playing && props.track) {
            setCurrentTrack(props.track);
            startTrack(props.track);
        };
    };

    const classes = `play ${(playing && (currentTrack == props.track || !props.track)) ? 'playing' : 'paused'}`

    return (
        <button
            className={classes}
            onClick={handleClick}
        ></button>
    );
};

const Controls = (props) => {
    const controls = <div className="controls">
        {props.children}
    </div>;

    return controls;
}

const OpenPad = (props) => {
    const style = {
        color: `${props.playing ? 'black' : '#ffffff00'}`,
        cursor: `${props.playing ? 'pointer' : 'default'}`,
    }

    const openPad = <button style={style} className="open-pad" onClick={() => props.playing && props.setShowPad(p => !p)}>▼</button>
    
    return openPad;
}

const Transport = (props) => {
    const classes = `transport ${props.active ? 'showing' : 'hidden'}`
    const style = {backgroundColor: `${props.active ? props.tracks[props.active].color : 'white'}`}

    const transport = <div style={style} className={classes}>
        {props.children}
    </div>;

    return transport;
};

const Track = (props) => {
    const track = <div style={{backgroundColor: props.tracks[props.track].color}} className="track">
        {props.children}
    </div>;

    return track;
}

const TrackList = (props) => {
    const trackList = <div className="track-list">
        {props.children}
    </div>;

    return trackList;
}

const CurrentTrackName = (props) => {
    const currentTrackName = <div className="current-track-name">
        <h1>{props.currentTrack}</h1>
    </div>

    return currentTrackName;
}

const Pad = (props) => {
    const {showPad, playing, tracks, currentTrack, setShowPad, setMod} = props.state

    const [ballX, setBallX] = useState(0);
    const [ballY, setBallY] = useState(0);
    const [mouseDown, setMouseDown] = useState(false);

    const padRef = useRef();

    useEffect(() => {
        setBallX(padRef.current.clientWidth/2);
        setBallY(padRef.current.clientHeight/2);
    }, [playing]);

    const onMouseUp = (e) => setMouseDown(false);
    const onMouseDown = (e) => {
        if (e.clientX < padRef.current.offsetLeft
            || e.clientX > padRef.current.offsetLeft + padRef.current.clientWidth
            || e.clientY < padRef.current.offsetTop
            || e.clientY > padRef.current.offsetTop + padRef.current.clientHeight) {
            setShowPad(p => !p);
        } else {
            setMouseDown(true);
        }
    };

    const onMouseMove = (e) => {
        if (mouseDown) {
            if (e.clientX < padRef.current.offsetLeft) {
                e.clientX = padRef.current.offsetLeft;
            } else if (e.clientX > padRef.current.offsetLeft + padRef.current.clientWidth) {
                e.clientX = padRef.current.offsetLeft + padRef.current.clientWidth;
            } else if (e.clientY < padRef.current.offsetTop) {
                e.clientY = padRef.current.offsetTop;
            } else if (e.clientY > padRef.current.offsetTop + padRef.current.clientHeight) {
                e.clientY = padRef.current.offsetTop + padRef.current.clientHeight;
            }

            setBallX(e.clientX-padRef.current.offsetLeft);
            setBallY(e.clientY-padRef.current.offsetTop);

            if (setMod) setMod([
                (e.clientX-padRef.current.offsetLeft)/padRef.current.clientWidth,
                1-(e.clientY-padRef.current.offsetTop)/padRef.current.clientHeight
            ]);
        }
    };

    const onTouchMove = (e) => {
        console.log(e);
    }

    const classes = `pad ${showPad ? 'showing' : 'hidden'}`
    const style = {backgroundColor: `${playing ? tracks[currentTrack].background : '#fffff9'}`}

    const pad = <div onMouseDown={onMouseDown} onTouchStart={onMouseDown} onMouseUp={onMouseUp} onTouchEnd={onMouseUp} className={classes}>
        <div className="hide-pad" onClick={() => setShowPad(p => !p)}/>
        <div ref={padRef} style={style} onMouseMove={onMouseMove} onTouchMove={onTouchMove} on className="pad-display">
            <svg width="100%" height="100%">
                <circle cx={ballX} cy={ballY} r="10" fill="#000" stroke="#000" strokeWidth="3" />
            </svg>
        </div>
    </div>

    return pad;
}


const App = ({}) => {
    const [currentTrack, setCurrentTrack] = useState(null);    
    const [end, setEnd] = useState(null);    
    const [playing, setPlaying] = useState(false);
    const [clean, setClean] = useState(null);
    const [showPad, setShowPad] = useState(false);

    const [setMod, setSetMod] = useState([0.5, 0.5]);

    const tracks = {
        "first": {"activate": first, "color": "#7eeada", "background": "#7ec4ea"},
        "sevens": {"activate": sevens, "color": "#92ea7e", "background": "#7eeaa0"}
    }

    const stop = (() => {
        Tone.Transport.stop();
        Tone.Transport.cancel();
        end();
    });

    const props = {
        stop,
        playing, setPlaying,
        end, setEnd,
        currentTrack, setCurrentTrack,
        clean, setClean,
        setSetMod,
        tracks};

    const style = {backgroundColor: `${playing ? tracks[currentTrack].background : '#fffff9'}`}

    const main = <div style={style} className="main">
        <Pad state={{showPad, playing, tracks, currentTrack, setShowPad, setMod}}/>
        <TrackList>
            <Track track={"first"} tracks={tracks}>
                <PlayButton state={props} track={"first"}/>
            </Track>
            <Track track={"sevens"} tracks={tracks}>
                <PlayButton state={props} track={"sevens"}/>
            </Track>
        </TrackList>
        <Transport active={currentTrack} tracks={tracks}>
            <Controls>
                <PlayButton state={props}/>
            </Controls>
            <OpenPad setShowPad={setShowPad} playing={playing} />
            <CurrentTrackName currentTrack={currentTrack} />
        </Transport>
    </div>;

    return main;
}

export default App;
