import './style.css';
import * as Tone from 'tone';

const get_random_number_between = (min, max) => {
    return Math.random() * (max - min) + min;
}

const get_random_element = arr => {
    return arr[Math.floor(Math.random()*arr.length)];
}

const schedulePlay = (instruments, mod) => {
    if (instruments.lead1.disposed) return;

    const lead_notes = ["A3", "C4", "D4", "E4", "G4", "A4", "C5", "D5", "E5", "G5"];
    const notes = ["A3", "C4", "D4", "E4", "G4"];
    const time = "5";

    const seq = new Tone.Sequence((time, note) => {
        instruments.synth.triggerAttackRelease(note, 0.1, time);
    }, ["C4", "E4", "G4", "B4", ["D4", "F4"], "A4", "C5"], "8n").start(0);

    const drumSeq = new Tone.Sequence((time, note) => {
        instruments.drums.triggerAttackRelease(note, 0.2, time);
    }, ["C1", "D1", ["C1", "C1"], "D1"], "4n").start(0);

    const hatSeq = new Tone.Sequence((time, note) => {
        if (Math.random() < mod.y) {
            instruments.drums.triggerAttackRelease(note, 0.2, time);
        }
    }, ["E1"], "8n").start(0);
};


const activate = async () => {
    const out = new Tone.Gain(0.3).toDestination();
    const compressor = new Tone.Compressor(-30, 3).connect(out);
    const gain = new Tone.Gain(0.9).connect(compressor);
    const reverb = new Tone.Reverb(3).connect(gain);

    const config = {
        oscillator: {
            type: "triangle",
            harmonicity: 0.5,
            modulationType: "sine"
        },
        envelope: {
            attackCurve: "exponential",
            attack: 1,
            decay: 1,
            sustain: 1,
            release: 1,
        },
        portamento: 0.3
    };

    const chord_config = {
        oscillator: {
            type: "triangle",
            harmonicity: 0.5,
            modulationType: "sine"
        },
        envelope: {
            attackCurve: "exponential",
            attack: 1,
            decay: 1,
            sustain: 1,
            release: 1,
        },
        portamento: 0.3
    };

    const bass_config = {
        oscillator: {
            type: "sine",
            harmonicity: 0.5,
            modulationType: "sine"
        },
        envelope: {
            attackCurve: "exponential",
            attack: 0.1,
            decay: 1,
            sustain: 1,
            release: 1,
        },
        portamento: 0.3
    };

    const lead1 = new Tone.Synth(chord_config).connect(reverb);
    const synth = new Tone.PolySynth(Tone.Synth, config).connect(gain);
    const bass = new Tone.Synth(bass_config).connect(gain);

    const drums = new Tone.Sampler({
        urls: {
            C1: "BD/BD0025.WAV",
            D1: "SD/SD1075.WAV",
            E1: "CH/CH.WAV"
        },
        baseUrl: "https://music.samiser.xyz/samples/tr-808/"
    }).connect(gain);

    lead1.volume.value = -7;
    synth.volume.value = -4;
    bass.volume.value = -7;

    let mod = {x: 0, y: 0};

    const setMod = (m) => {
        mod.x = m[0];
        mod.y = m[1];
        
        Tone.Transport.bpm.value = 80 + mod.x*40;
    };

    const play = () => {
        schedulePlay({lead1, synth, bass, drums}, mod);
        return () => {
            synth.releaseAll();
            synth.context._timeouts.cancel(0);
            synth.dispose();
        }
    }

    const stop = () => {
        [
            out,
            compressor,
            gain,
            reverb,
            lead1,
            bass
        ].map(node => node.dispose());
    }

    return [play, stop, setMod];
};

export default activate;
