import './style.css';
import * as Tone from 'tone';

const get_random_number_between = (min, max) => {
    return Math.random() * (max - min) + min;
}

const get_random_element = arr => {
    return arr[Math.floor(Math.random()*arr.length)];
}

const schedulePlay = (instruments, mod) => {
    if (instruments.lead1.disposed) return;

    const getNotes = (value) => {
        if (value < 0.2) {
            return [];
        } else if (value < 0.4) {
            return ["A3", "C4", "D4"];
        } else if (value < 0.6) {
            return ["A3", "C4", "D4", "E4", "G4", "A4"];
        } else if (value < 0.8) {
            return ["A3", "C4", "D4", "E4", "G4", "A4", "C5", "D5", "E5"];
        } else {
            return ["A3", "C4", "D4", "E4", "G4", "A4", "C5", "D5", "E5", "G5", "A5", "C6"];
        }
    }
    
    //const lead_notes = ["A3", "C4", "D4", "E4", "G4", "A4", "C5", "D5", "E5", "G5"];
    const lead_notes = getNotes(mod.x);
    const notes = ["A3", "C4", "D4", "E4", "G4"];
    const time = "5";

    if (lead_notes) {
        lead_notes.forEach(note => {
            const note_time = get_random_number_between(0.5, 4.5);
            instruments.synth.triggerAttackRelease(note, "0.3s", `+${note_time}`);
        });
    }
    
    [instruments.lead1, instruments.lead2].forEach(lead => {
        lead.triggerAttackRelease(get_random_element(notes), time);
    });

    const bass_notes = ["A2", "C3", "D3", "E2", "G2"];
    instruments.bass.triggerAttackRelease(
        get_random_element(bass_notes), time
    );

    Tone.Transport.scheduleOnce(() => {
        schedulePlay(instruments, mod);
    }, `+${time}`);
};


const activate = async () => {
    const out = new Tone.Gain(0.3).toDestination();
    const compressor = new Tone.Compressor(-30, 3).connect(out);
    const filter = new Tone.Filter(1000, "lowpass").connect(compressor);
    const gain = new Tone.Gain(0.9).connect(filter);
    const reverb = new Tone.Reverb(2).connect(gain);

    const config = {
        oscillator: {
            type: "triangle",
            harmonicity: 0.5,
            modulationType: "sine"
        },
        envelope: {
            attackCurve: "exponential",
            attack: 1,
            decay: 1,
            sustain: 1,
            release: 1,
        },
        portamento: 0.3
    };

    const chord_config = {
        oscillator: {
            type: "triangle",
            harmonicity: 0.5,
            modulationType: "sine"
        },
        envelope: {
            attackCurve: "exponential",
            attack: 1,
            decay: 1,
            sustain: 1,
            release: 1,
        },
        portamento: 0.3
    };

    const bass_config = {
        oscillator: {
            type: "sine",
            harmonicity: 0.5,
            modulationType: "sine"
        },
        envelope: {
            attackCurve: "exponential",
            attack: 1,
            decay: 1,
            sustain: 1,
            release: 1,
        },
        portamento: 0.3
    };

    //const synth = new Tone.PolySynth(Tone.Synth, config).connect(reverb);
    const lead1 = new Tone.Synth(chord_config).connect(reverb);
    const lead2 = new Tone.Synth(chord_config).connect(reverb);
    const synth = new Tone.PolySynth(Tone.Synth, config).connect(reverb);
    const bass = new Tone.Synth(bass_config).connect(gain);

    lead1.volume.value = -7;
    lead2.volume.value = -7;
    synth.volume.value = -4;
    bass.volume.value = -7;

    let mod = {x: 0.5, y: 0.5};

    const setMod = (m) => {
        mod.x = m[0];
        mod.y = m[1];

        filter.frequency.value = 500 + mod.y*10000;
    };

    const play = () => {
        schedulePlay({lead1, lead2, synth, bass}, mod);
        return () => {
            synth.releaseAll();
            synth.context._timeouts.cancel(0);
            synth.dispose();
        }
    }

    const stop = () => {
        [
            out,
            compressor,
            gain,
            reverb,
            lead1,
            lead2,
            bass
        ].map(node => node.dispose());
    }

    return [play, stop, setMod];
};

export default activate;
